import { Select, SelectProps } from 'antd'
import { useRouter } from 'next/router'
import React from 'react'
import { getInitials } from 'src/app/helpers'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { getFinalPrice, selectAmount, selectVintageId, setVintageId } from 'src/features/compensation/compensationSlice'
import { VintageItem } from 'src/models/project'

const { Option } = Select

interface VintageSelectProps extends SelectProps {
  vintageItems?: VintageItem[]
}
const VintageSelect: React.FC<VintageSelectProps> = ({ vintageItems, ...props }) => {
  const dispatch = useAppDispatch()
  const vintageId = useAppSelector(selectVintageId)
  const { query } = useRouter()
  const amount = +useAppSelector(selectAmount)
  const handleSelect = (id: number) => {
    dispatch(setVintageId(id))
    const initials = getInitials(query, location.host)
    dispatch(getFinalPrice(initials, amount))
  }
  return (
    <Select {...props} onSelect={handleSelect} value={vintageId}>
      {vintageItems.filter(c => c.available_volume_credits > +amount)
        .map(c => <Option label={c.year} value={c.id} key={c.id + '-' + c.year}>{c.year}</Option>)}
    </Select >
  )
}
export default VintageSelect
