import React from 'react'
import { useTranslation } from 'next-i18next'
import styles from './PersonalInfo.module.scss'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { getFinalPrice, selectAmount, selectCommercialComms, selectCountries, selectOffsetReason, selectOffsetReasonText, selectOffsetReasons, selectPersonalInfo, selectTerms, selectWhitelabel, setCommercialComms, setOffsetReasonText, setPersonalInfo, setTerms } from 'src/features/compensation/compensationSlice'
import { Checkbox, Form, Input, Select } from 'antd'
import { useRouter } from 'next/router'
import { getInitials, hideFooterOnFocus, showFooterOnBlur } from 'src/app/helpers'
import { basic_fields, fiscal_fields } from './fields'
import RedsysForm from 'src/components/RedsysForm'
import OffsetReasonSelect from 'src/components/OffsetReasonSelect/OffsetReasonSelect'
import CountrySelect from 'src/components/CountrySelect/CountrySelect'

const { Option } = Select

const PersonalInfo: React.FC = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const dispatch = useAppDispatch()
  const personalInfo = useAppSelector(selectPersonalInfo)
  const offsetReasons = useAppSelector(selectOffsetReasons)
  const countries = useAppSelector(selectCountries)
  const amount = +useAppSelector(selectAmount)
  const offsetReason = useAppSelector(selectOffsetReason)
  const offsetReasonText = useAppSelector(selectOffsetReasonText)
  const terms = useAppSelector(selectTerms)
  const commercialComms = useAppSelector(selectCommercialComms)
  const { customer_type, terms_pdf, privacy_pdf, offset_reason_method } = useAppSelector(selectWhitelabel)

  const handleChange = (key: string) => (e) => {
    dispatch(setPersonalInfo({ ...personalInfo, [key]: e.target.value }))
  }
  const handleCountryChange = country => {
    const initials = getInitials(router.query, location.host)
    dispatch(setPersonalInfo({ ...personalInfo, country }))
    dispatch(getFinalPrice(initials, amount))
  }
  const handleSelectChange = (value) => {
    dispatch(setPersonalInfo({
      ...personalInfo,
      last_name: '⠀', first_name: '', customer_type: value,
    }))
  }
  const handleChangeCommercialComms = () => {
    localStorage.setItem('consent', JSON.stringify({ 'commercial_communications': !commercialComms }))
    dispatch(setCommercialComms(!commercialComms))

  }
  const handleOffsetReasonTextChange = (e) => { dispatch(setOffsetReasonText(e.target.value)) }
  return (
    <div className={styles.PersonalInfo}>
      <Form name="basic" wrapperCol={{ span: 23 }} initialValues={personalInfo}>
        <div className="personal-form">
          {customer_type === 'choose' &&
            <Select className="customer-type-select" aria-label="customer type select" size="large"
              onSelect={handleSelectChange} value={personalInfo.customer_type}>
              <Option value="individual">{t('personalInfo.individual')}</Option>
              <Option value="company">{t('personalInfo.company')}</Option>
            </Select>
          }
          <div className="form-container">
            {basic_fields(personalInfo.customer_type, t).filter(f => f.name).map(field =>
              <Form.Item key={field.name} className="inputs" {...field} label={null}>
                <Input placeholder={field.label || t('personalInfo.' + field.name)} onChange={handleChange(field.name)}
                  value={personalInfo[field.name]} onFocus={hideFooterOnFocus} onBlur={showFooterOnBlur} />
              </Form.Item>)}

          </div>
          <div className="description-container">
            <p className="description">
              {t('personalInfo.invoiceDescription', { vat_id: t('personalInfo.vat_number' + personalInfo.customer_type) })}
            </p>
          </div>
          <div className="form-container">
            {fiscal_fields(personalInfo.customer_type, t).map((field, i) => (i == 0 || personalInfo.vat_number) &&
              <Form.Item key={field.name} className="inputs" {...field} valuePropName={field.name}>
                <Input placeholder={field.placeholder || t('personalInfo.' + field.name)} onChange={handleChange(field.name)}
                  onFocus={hideFooterOnFocus} onBlur={showFooterOnBlur} value={personalInfo[field.name]} />
              </Form.Item>)}
            <CountrySelect onChange={handleCountryChange} countries={countries} className="country-select"
              onFocus={hideFooterOnFocus} onBlur={showFooterOnBlur} value={personalInfo.country} />
          </div>
          <div className="form-container">
            {['fixed', 'fixed_open'].includes(offset_reason_method) &&
              <OffsetReasonSelect offsetReasons={offsetReasons} className="offset-reason-select" />}
            <br />
            {offset_reason_method === 'open' ||
              (offset_reason_method === 'fixed_open' && offsetReason === 12) &&
              <div className="inputs">
                <Input onChange={handleOffsetReasonTextChange}
                  onFocus={hideFooterOnFocus} onBlur={showFooterOnBlur} value={offsetReasonText} />
              </div>
            }</div>
          <div className="form-container">
            <Checkbox checked={commercialComms} onChange={handleChangeCommercialComms}>
              {t('personalInfo.consentCommercialComms')}
            </Checkbox>
          </div>
          <div className="form-container">
            <Checkbox checked={terms} onChange={() => dispatch(setTerms(!terms))}>
              {t('personalInfo.agreeTerms')}
              <a href={terms_pdf || `files/terms_${router.locale}.pdf`} target="_blank"
                rel="noreferrer noopener" className="footer-text">
                {t('footer.terms')}</a>&nbsp;
              <a href={privacy_pdf || `files/privacy_policy_${router.locale}.pdf`} target="_blank"
                rel="noreferrer noopener" className="footer-text">
                {t('personalInfo.privacyPolicy')}</a>
            </Checkbox>
          </div>
        </div>
        <br />
        <RedsysForm />
      </Form>
    </div>
  )
}
export default PersonalInfo
