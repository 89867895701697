import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React from 'react'
import styles from './Redsysiframe.module.scss'
const RedsysIframe: React.FC<{}> = () => {
  const { t } = useTranslation()
  return (
    <div className={styles['redsys-iframe-container']}>
      <label className="cardinfo-label" htmlFor="card-number">{t('payment.CreditCardNumber')}</label>
      <div className="cardinfo-card-number">
        <div className='input-wrapper' id="card-number"></div>
        <div className="card-icons">
          <Image width={60} height={60} src="/images/icons/visa.svg" alt="visaicon" />
          <Image width={60} height={60} src="/images/icons/mastercard.svg" alt="mastercardicon" />
        </div>
      </div>
      <div className="expiry-date">
        <div className="cardinfo-exp-date">
          <label className="cardinfo-label" htmlFor="expiration-date">{t('payment.MonthExpiration')}</label>
          <div className='input-wrapper' id="expiration-month"></div>
        </div>
        <div className="cardinfo-exp-date2">
          <label className="cardinfo-label" htmlFor="expiration-date2">{t('payment.YearExpiration')}</label>
          <div className='input-wrapper' id="expiration-year"></div>
        </div>
        <div className="cardinfo-cvv">
          <label className="cardinfo-label" htmlFor="cvv">{t('payment.CVC')}</label>
          <div className='input-wrapper' id="cvv"></div>
        </div>
      </div>
      <div id="redsys-button"></div>
      <form name="datos">
        <input type="hidden" id="token"></input>
        <input type="hidden" id="errorCode"></input>
      </form>
    </div>
  )

}
export default RedsysIframe
