import { useTranslation } from 'next-i18next'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { AmountInput } from 'src/components/AmountInput/AmountInput'
import BaseButton from 'src/components/BaseButton/BaseButton'
import { selectAmount, selectEquivalence, setStep } from 'src/features/compensation/compensationSlice'
import styles from './EmbeddedAmount.module.scss'
const EmbeddedAmount = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const amount = useAppSelector(selectAmount)
  const handleNextStep = () => dispatch(setStep(2))
  const { result: { value: treesSaved } } = useAppSelector(selectEquivalence)
  return (
    <div className={styles.AmountView}>
      <div className="amount-layout">

        <div className="carbon-tones-container">
          <AmountInput />
          <BaseButton type="submit" label={t('general.Continue')} disabled={!amount} onClick={handleNextStep} />
        </div>
        <div className="trees-saved-container">
          {treesSaved && <div className="blurry-trees-container">
            <div className="trees-text">
              {t('general.TreesSaved', { amount })}
            </div>
            <div className="trees-total">
              <div className="tree-icon" />
              &nbsp;+{treesSaved}
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default EmbeddedAmount
