import React from 'react'
import { useAppSelector } from 'src/app/hooks'
import { selectAmount, selectCurrency, selectEquivalence, selectFinalPrice, selectFinalUnitPrice, selectSelectedProject, selectStep } from 'src/features/compensation/compensationSlice'
import styles from './CartResume.module.scss'

import { getFormattedPrice } from 'src/app/helpers'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import VintageSelect from '../VintageSelect/VintageSelect'
const CartResume: React.FC = () => {
  const amount = +useAppSelector(selectAmount)
  const { locale } = useRouter()
  const { t } = useTranslation()
  const currency = useAppSelector(selectCurrency)
  const selectedProject = useAppSelector(selectSelectedProject)
  const finalPrice = useAppSelector(selectFinalPrice)
  const finalUnitPrice = useAppSelector(selectFinalUnitPrice)
  const { result: { value: treesSaved } } = useAppSelector(selectEquivalence)
  const step = useAppSelector(selectStep)
  if (!finalUnitPrice) {
    return <div></div>
  }
  const { total_amount, sell_vat, fee_vat, fixed_amount_transaction_fee, discount_amount } = finalPrice
  const unitPrice = finalUnitPrice.sell_base_amount + finalUnitPrice.fee_amount
  const vat = (sell_vat + fee_vat)
  const subtotal = total_amount - vat - fixed_amount_transaction_fee + discount_amount
  const discountFormatted = getFormattedPrice(discount_amount, locale, currency.code)
  const vatFormatted = getFormattedPrice(vat, locale, currency.code)
  const transactionFeeFormatted = getFormattedPrice(fixed_amount_transaction_fee, locale, currency.code)
  const subtotalFormatted = getFormattedPrice(subtotal, locale, currency.code)
  const totalFormatted = total_amount && getFormattedPrice(total_amount, locale, currency.code)
  const co2AmountFormatted = new Intl.NumberFormat(locale).format(amount)
  const unit = t('general.Unit.' + (selectedProject?.kind || 'compensation'), { count: +amount })
  return (
    <div className={styles.CartResume} style={{ visibility: total_amount && step > 0 ? 'initial' : 'hidden' }}>
      {!!total_amount && step > 0 &&
        <div className="card-resume-container">
          <div className="offset-circle">
            <div>{t('general.Offset')}</div>
            <div className="co2-amount">{co2AmountFormatted}</div>
            <div>{unit}</div>
          </div>
          <div className="project-container">
            <img src={selectedProject.cover} alt="" />
            <div className="project-name-container">
              <div className="project-name">{selectedProject.name}</div>
              <div className="amounts">
                {getFormattedPrice(unitPrice, locale, currency.code)} x {co2AmountFormatted + ' ' + unit}
              </div>
            </div>
          </div>

          <div className="amount-container">
            <div className="subtotal">
              {selectedProject.is_vintage && <span>Year</span>}
              {selectedProject.is_vintage && <VintageSelect vintageItems={selectedProject.vintage_items} style={{ width: '80px' }} />}
            </div>
          </div>

          <div className="amount-container">
            <div className="subtotal">
              <span>Subtotal</span>
              <span>{subtotalFormatted}</span>
            </div>
          </div>

          <div className="amount-container">
            <div className="subtotal">
              <span>{t('general.VAT')}</span>
              <span>{vatFormatted}</span>
            </div>
          </div>

          <div className="amount-container">
            <div className="subtotal">
              <span>{t('general.TransactionFee')}</span>
              <span>{transactionFeeFormatted}</span>
            </div>
          </div>

          {!!discount_amount && <div className="amount-container">
            <div className="subtotal" >
              <span>{t('general.Discount')}</span>
              <span className="discount">- {discountFormatted}</span>
            </div>
          </div>}
          <div className="order-total">
            <span>Total</span>
            <span>{totalFormatted}</span>
          </div>
          {treesSaved ? <div className="trees-saved">

            <div className="trees-total">
              <Image width={22} height={30} src="/images/icons/tree.svg" alt="tree icon" />
              &nbsp;{treesSaved} +
            </div>
            <div className="tree-text">
              {t('general.TreesEquivalent')}
            </div>
          </div> : ''}
        </div>
      }
    </div>
  )
}

export default CartResume
