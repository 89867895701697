import { Select, SelectProps } from 'antd'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { selectOffsetReason, setOffsetReason } from 'src/features/compensation/compensationSlice'
import { OffsetReason } from 'src/models/compensation'
const { Option } = Select

interface OffsetReasonSelectProps extends SelectProps {
  offsetReasons?: OffsetReason[]
}
const OffsetReasonSelect: React.FC<OffsetReasonSelectProps> = ({ offsetReasons, ...props }) => {
  const dispatch = useAppDispatch()
  const offsetReason = useAppSelector(selectOffsetReason)
  const handleSelect = o => { dispatch(setOffsetReason(o)) }
  return (
    <Select {...props} value={offsetReason} onSelect={handleSelect}>
      {offsetReasons.map(o => <Option label={o.text} value={o.id} key={'offset-reason-' + o.id}>{o.text}</Option>)}
    </Select >
  )
}

export default OffsetReasonSelect
