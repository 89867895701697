import { useTranslation } from 'next-i18next'
import React from 'react'
import { SdgCompilance } from 'src/models/project'

const ProjectSDG: React.FC<{ sdg: SdgCompilance }> = ({ sdg }) => {
  const { t } = useTranslation()
  return (
    <div className="project-SDG" key={sdg.id + '-sdg'}>
      <div className="SDG-image" style={{
        backgroundImage: `url(${sdg.icon})`,
      }} />
      <div className="SDG-text">
        <div className="SDG-title"> {t('sdgs.' + sdg.id)} </div>
        <div className="SDG-description"> {t('sdgs.' + sdg.id + '-title')} </div>
      </div>
    </div>
  )
}

export default ProjectSDG
