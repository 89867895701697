import { useTranslation } from 'next-i18next'
import React from 'react'
import { useAppSelector } from 'src/app/hooks'
import { selectStep, selectWhitelabel } from 'src/features/compensation/compensationSlice'
import styles from './StepProgress.module.scss'
const StepProgress: React.FC = () => {
  const step = useAppSelector(selectStep)
  const { t } = useTranslation()
  const { primary_color } = useAppSelector(selectWhitelabel)
  return (
    <div className={styles['progress-container']}>
      <div>
        <div className="progress-title">
          {t('general.Progress')}
          <div className="progress-quotient" style={{ color: primary_color }}>
            {step}/4
          </div>
        </div>
        <div className="progress-bar-container">
          <div className="progress-bar">
            <div style={{ width: (step * 100 / 4) + '%', backgroundColor: primary_color }}
              className="progress-filled" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepProgress
