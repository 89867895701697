import { Steps } from 'antd'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { AmountInput } from 'src/components/AmountInput/AmountInput'
import BaseButton from 'src/components/BaseButton/BaseButton'
import { selectAmount, selectSelectedProject, selectStep, selectWhitelabel, setStep } from 'src/features/compensation/compensationSlice'
import ChooseProject from 'src/containers/ChooseProject/ChooseProject'
import PersonalInfo from 'src/containers/PersonalInfo/PersonalInfo'
import styles from './MainStepper.module.scss'
import { isNoRedirect } from 'src/app/helpers'
import StepProgress from 'src/components/StepProgress/StepProgress'
import PaymentContainer from 'src/containers/PaymentContainer/PaymentContainer'
const { Step } = Steps

const MainStepper: React.FC = () => {

  const step = useAppSelector(selectStep)
  const amount = useAppSelector(selectAmount)
  const selectedProject = useAppSelector(selectSelectedProject)
  const { payment_gateway } = useAppSelector(selectWhitelabel)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const handleNextStep = () => dispatch(setStep(2))
  const onChange = (value: number) => {
    dispatch(setStep((value)))
  }
  const noRedirect = isNoRedirect(location, payment_gateway)
  return (
    <div className={styles['stepperContainer']}>
      <StepProgress />
      <Steps current={step} onChange={onChange} direction="vertical">
        <br />
        <Step className="amount-step" title={t('StepsTitles.Unit.' + (selectedProject?.kind || 'compensation'), { count: +amount })} description={t('StepsDescriptions.1')} subTitle={t('general.Edit')} stepNumber={1} />
        {step === 1 && <div className="change-amount-container">
          <AmountInput />
          <BaseButton type="submit" label={t('general.Continue')} disabled={!amount} onClick={handleNextStep} />
        </div>}
        <Step title={t('general.ChooseProject')} description={t('StepsDescriptions.2')} subTitle={t('general.Edit')} stepNumber={2} stepIndex={2} />
        {step === 2 && <ChooseProject />}
        <Step title={t('StepsTitles.3')} description={t('StepsDescriptions.3')} subTitle={t('general.Edit')} stepNumber={3} stepIndex={3} />
        {step === 3 && <PersonalInfo />}

        {noRedirect && <Step title={t('StepsTitles.4')} subTitle={t('general.Edit')} stepNumber={4} stepIndex={4} />}
      </Steps>
      {noRedirect && step === 4 &&
        <PaymentContainer />
      }
    </div>
  )
}

export default MainStepper
