import React from 'react'
import { useAppSelector } from 'src/app/hooks'
import { selectWhitelabel } from 'src/features/compensation/compensationSlice'
import CollectJSSection from 'src/components/NMI_payment/CollectJSSection'
import RedsysIframe from 'src/components/RedsysIframe/RedSysIframe'

const CreditCardIframe = () => {
  const { payment_gateway } = useAppSelector(selectWhitelabel)
  return (
    <>
      {payment_gateway === 'NMI' && <CollectJSSection />}
      {payment_gateway?.includes('REDSYS')  && <RedsysIframe />}
    </>
  )
}

export default CreditCardIframe
