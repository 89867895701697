import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { finishOffset, selectAmount, selectCertificate, selectCommercialComms, selectCurrency, selectFinalPrice, selectOffsetReason, selectOffsetReasonText, selectPersonalInfo, selectSelectedProject, selectVintageId, selectWhitelabel, setCertificate } from 'src/features/compensation/compensationSlice'
import styles from 'src/components/RedsysIframe/Redsysiframe.module.scss'
import BaseButton from '../BaseButton/BaseButton'
import { useRouter } from 'next/router'
import { notification } from 'antd'
import Spinner from '../Spinner/Spinner'
const CollectJSSection: React.FC = () => {
  const { secondary_color, primary_color } = useAppSelector(selectWhitelabel)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [valid, setValid] = useState(false)
  const [status, setStatus] = useState('')
  const certificate = useAppSelector(selectCertificate)
  const { query, locale, push } = useRouter()
  const { total_amount } = useAppSelector(selectFinalPrice)
  const selectedProject = useAppSelector(selectSelectedProject)
  const currency = useAppSelector(selectCurrency)
  const amount = +useAppSelector(selectAmount)
  const personalInfo = useAppSelector(selectPersonalInfo)
  const commercialComms = useAppSelector(selectCommercialComms)
  const vintageId = useAppSelector(selectVintageId)
  const offsetReason = useAppSelector(selectOffsetReason)
  const offsetReasonText = useAppSelector(selectOffsetReasonText)

  const paymentCallback = async ({ token }) => {
    setStatus('loading')
    try {
      const initials = query.initials as string || location.host.split('.').reverse()[2] || 'localhost'
      const offset = {
        project: selectedProject.id, co2_amount: amount, vintage_item: vintageId,
        offset_reason: offsetReason, currency_token: currency.id,
        offset_reason_text: offsetReasonText,
        extra_data: {
          ...query,
          language: locale
        }
      }
      const ticket_data = { ...personalInfo, last_name: personalInfo.last_name || '⠀' }
      const consent = { commercial_communications: !commercialComms }
      const payload = {
        offset, ticket_data, initials, token,
        integration_code: initials, consent, amount: total_amount
      }
      const { certificate: cert } = await dispatch(finishOffset(payload))
      dispatch(setCertificate(cert))
      push('thank-you', undefined, { locale })
    } catch (error) {
      const err = error?.response?.data?.responsetext
      notification.error({ message: err || t('general.PaymentError'), top: 0 })
    } finally {
      setStatus('loaded')
    }

  }
  useEffect(() => {
    window['CollectJS']?.configure({
      variant: 'inline',
      styleSniffer: true,
      callback: paymentCallback,
      customCss: {
        'border': '1px solid',
        'border-radius': '8px',
        height: '48px',
        'padding': '8px 16px',
        'border-color': secondary_color || '#40a9ff'
      },
      invalidCss: {
        'border-color': 'red'
      },
      validCss: {
        'border-color': secondary_color
      },
      placeholderCss: {
        color: '#777E8B'
      },
      focusCss: {
        'border-color': primary_color || '#D9D9D9'
      },
      fields: {
        ccnumber: {
          placeholder: t('payment.CreditCardNumber'),
          selector: '#ccnumber'
        },
        ccexp: {
          placeholder: t('payment.ExpirationMMYY'),
          selector: '#ccexp'
        },
        cvv: {
          placeholder: t('payment.CVC'),
          selector: '#cvc'
        }
      },
      validationCallback: function (field, status: boolean) {
        setValid(status)
      },
    })
  }, [])
  const handleClick = () => {
    setStatus('loading')
    window['CollectJS'].startPaymentRequest()
  }
  return (
    <div className={styles['redsys-iframe-container']}>
      <div className="cardinfo-card-number" style={{ display: certificate ? 'none' : 'flex' }}>
        <div id="ccnumber" />
        <div className="card-icons">
          <Image width={60} height={60} src="/images/icons/visa.svg" alt="visaicon" />
          <Image width={60} height={60} src="/images/icons/mastercard.svg" alt="mastercardicon" />
        </div>
      </div>
      <div className="exp-cvc" style={{ display: certificate ? 'none' : 'flex' }}>

        <div id="ccexp" style={{ width: '150px', marginRight: '16px' }} />
        <div id="cvc" style={{ width: '160px' }} />
      </div>
      <br />
      <BaseButton label={t('general.OffsetNow')} onClick={handleClick} disabled={!valid} style={{ display: certificate ? 'none' : 'revert' }} />
      {status === 'loading' && <Spinner />}
    </div >
  )
}
export default CollectJSSection
