import { useRouter } from 'next/router'
import React from 'react'
import { getFormattedDate, getFormattedPrice } from 'src/app/helpers'
import { RecentPurchase } from 'src/models/project'
import styles from './ProjectPurchase.module.scss'
const ProjectPurchase: React.FC<{ purchase: RecentPurchase }> = ({ purchase }) => {
  const { locale } = useRouter()
  const date = new Date(purchase.created.split('/').reverse().join('/'))
  return (
    <div className={styles['purchase-container']}>
      <div className="project-purchase" key={'purchase-' + purchase.purchase_id} >
        <div>
          <div className="project-flag" style={{ backgroundImage: `url(${purchase.country_flag})` }} />
          <span>{purchase.country_code}</span>
        </div>
        <div>
          {purchase.currency_code && <div className="amount">
            {getFormattedPrice(purchase.amount_value, locale, purchase.currency_code)}
          </div>}
          <div>{purchase.amount} tCO₂</div>
        </div>
        <div className="purchase-date">{getFormattedDate(date, locale)}</div>
      </div>
    </div>
  )
}

export default ProjectPurchase
