import { useTranslation } from 'next-i18next'
import React from 'react'
import { useAppSelector } from 'src/app/hooks'
import BaseButton from 'src/components/BaseButton/BaseButton'
import { selectWhitelabel } from 'src/features/compensation/compensationSlice'
import styles from './FAQ.module.scss'
const FAQ: React.FC = () => {

  const { t } = useTranslation()
  const { secondary_color, show_faq } = useAppSelector(selectWhitelabel)
  const color = secondary_color || '#1C64F2'
  return (
    <section className={styles['FAQContainer']} >
      {
        show_faq && Array(3).fill(0).map((_, i) => (
          <article key={i + '-faq-article'}>
            <div>
              <div className="question">
                {t('FAQ.' + (i + 1) + '.Question')}
              </div>
              <p className="answer">
                {t('FAQ.' + (i + 1) + '.Answer')}
              </p>
            </div>
            <BaseButton label={t('FAQ.ReadMore')}
              style={{ borderColor: color, color }} onClick={() => window.open(t('FAQ.' + (i + 1) + '.Link'))} />
          </article>
        ))
      }
    </section >
  )
}

export default FAQ
