import { Collapse, Modal } from 'antd'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import { getFormattedPrice } from 'src/app/helpers'
import { useAppSelector } from 'src/app/hooks'
import { selectCurrency, selectSelectedProject } from 'src/features/compensation/compensationSlice'
import ProjectCertification from './ProjectPurchases/ProjectCertification'
import ProjectInfo from './ProjectPurchases/ProjectInfo'
import ProjectPurchase from './ProjectPurchases/ProjectPurchases'
import ProjectSDG from './ProjectPurchases/ProjectSDG'
const { Panel } = Collapse
const ProjectModal: React.FC<{ visible: boolean, setVisible: Function }> = ({ visible, setVisible }) => {
  const { locale } = useRouter()
  const { t } = useTranslation()
  const closeModal = () => { setVisible(false) }
  const selectedProject = useAppSelector(selectSelectedProject)
  const currency = useAppSelector(selectCurrency)
  if (!selectedProject?.id) { return <div></div> }
  const { sell_base_amount, fee_amount } = selectedProject.is_vintage ?
    selectedProject.vintage_items[0].final_unit_price
    : selectedProject.final_unit_price
  const unitPrice = sell_base_amount + fee_amount
  const getDescription = (lang) => selectedProject.descriptions.find(desc => desc.language === lang)?.text
  const description = getDescription(locale) || getDescription('en')
  const unit = t('general.Unit.' + (selectedProject?.kind || 'compensation'), { count: 1 })
  return (
    <Modal
      title={<h2>{t('projectModal.AboutTheProject')}</h2>}
      centered
      open={visible}
      footer={null}
      width={1000}
      className="project-modal"
      onCancel={closeModal}
    >
      <div className="project-main-info-container">
        <div className="project-image"
          style={{
            backgroundImage: `url(${selectedProject.cover})`,
          }}>

          {selectedProject.sdg_compliances?.length > 1 && <div className="sdgs-container">
            <Image width={30} height={30} src="/images/icons/sdg.svg" alt="sdg icon" />
            <span className="sdgs-total-count">{selectedProject.sdg_compliances.length}</span>
          </div>}
        </div>
        <div className='project-data' >
          <div className="project-kind">
            <div className="elipsis"></div><span>{t('projectModal.' + selectedProject.kind)}</span>
          </div>
          <div className="project-title">{selectedProject.name}</div>
          <div>
            <div className="project-price" >
              <span>{getFormattedPrice(unitPrice, locale, currency.code)} / {unit}</span>
            </div>
          </div>
        </div>
      </div>
      <Collapse defaultActiveKey={['1']} ghost expandIconPosition="end">
        <Panel className="project-description" header={t('projectModal.Description')} key="1">
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </Panel>
        <hr />
        {selectedProject.sdg_compliances?.length > 0 && <Panel className="project-SDGs" header={t('sdgs.SDGsDescription')} key="2">
          {selectedProject.sdg_compliances.map(sdg => <ProjectSDG sdg={sdg} key={sdg.id + '-sdg'} />)}
        </Panel>}
        <Panel className="project-info" header={t('projectModal.ProjectInfo')} key="3">
          <ProjectInfo project={selectedProject} />
        </Panel>
        {selectedProject.pictures?.length > 0 && <Panel className="project-gallery" header={t('projectModal.Gallery')} key="4">
          {selectedProject.pictures.map(picture => <div className="project-picture" key={'picture-' + picture.id} style={{ backgroundImage: `url(${picture.image})` }}></div>)}
        </Panel>}
        <Panel className="project-certification" header={t('projectModal.Certification')} key="5">
          <ProjectCertification project={selectedProject} />
        </Panel>
        <Panel className="project-purchases" header={t('projectModal.RecentPurchases')} key="6">
          {selectedProject.recent_purchases.map(purchase =>
            <ProjectPurchase purchase={purchase} key={purchase.purchase_id} />
          )}
        </Panel>
      </Collapse>
      <div >
      </div>
    </Modal >
  )
}
export default ProjectModal
