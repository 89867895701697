import { Rule } from 'antd/lib/form'
import { TFunction } from 'next-i18next'
import { customer_type } from 'src/models/compensation'
export const basic_fields = (customer_type: customer_type, t: TFunction) => [
  {
    name: 'first_name', label: t('personalInfo.' + (customer_type === 'company' ? 'company_name' : 'first_name')),
    rules: [{ required: true }] as Rule[]
  },
  customer_type === 'individual' && ({ name: 'last_name', rules: [{ required: true }] as Rule[] }),
  { name: 'email', rules: [{ required: true, type: 'email' }] as Rule[] }
]
export const fiscal_fields = (customer_type: customer_type, t: TFunction) => [
  {
    name: 'vat_number',
    placeholder: t('personalInfo.vatPlaceholder' + customer_type)
  },
  { name: 'street', rules: [{ required: true }] as Rule[] },
  { name: 'postal_code', rules: [{ required: true }] as Rule[] },
  { name: 'city', rules: [{ required: true }] as Rule[] },
  { name: 'state', rules: [{ required: true }] as Rule[] },
]
