import React, { useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import BaseButton from 'src/components/BaseButton/BaseButton'
import { selectAmount, selectCurrency, selectFinalPrice, selectOffsetReason, selectOffsetReasonText, selectPersonalInfo, selectSelectedProject, selectStep, selectTerms, selectVintageId, selectWhitelabel, setStep } from 'src/features/compensation/compensationSlice'
import { createCreditCardOrder } from 'src/features/compensation/compensationAPI'
import { useRouter } from 'next/router'
import { basic_fields, fiscal_fields } from 'src/containers/PersonalInfo/fields'
import { TrackPayload } from 'src/features/visitors/models'
import { trackEvent } from 'src/features/visitors/actions'
import { gtm_view } from 'src/app/helpers'
import { WHITELABEL_PAGES } from 'src/features/visitors/constants'

const RedsysForm: React.FC = () => {
  const [form, setForm] = useState({ Ds_SignatureVersion: '', Ds_MerchantParameters: '', Ds_Signature: '' })
  const { t } = useTranslation()
  const { query, route, locale } = useRouter()
  const { payment_gateway } = useAppSelector(selectWhitelabel)
  const dispatch = useAppDispatch()
  const step = useAppSelector(selectStep)
  const amount = +useAppSelector(selectAmount)
  const finalPrice = useAppSelector(selectFinalPrice)
  const selectedProject = useAppSelector(selectSelectedProject)
  const currency = useAppSelector(selectCurrency)
  const personalInfo = useAppSelector(selectPersonalInfo)
  const terms = useAppSelector(selectTerms)
  const formRef = useRef(null)
  const vintageId = useAppSelector(selectVintageId)
  const offsetReason = useAppSelector(selectOffsetReason)
  const offsetReasonText = useAppSelector(selectOffsetReasonText)

  if (!selectedProject?.id) { return <div></div> }
  const getInitials = () => query.initials as string || location.host.split('.').reverse()[2] || 'localhost'
  const handleClick = async () => {
    if (payment_gateway === 'NMI') {
      return dispatch(setStep(4))
    }
    const initials = getInitials()
    const searchParams = new URLSearchParams({
      initials, email: personalInfo.email,
      reason: '1', order: ''
    })
    const frontURL = window.location.origin
    const offset = {
      project: selectedProject.id,
      co2_amount: amount,
      offset_reason: offsetReason,
      offset_reason_text: offsetReasonText,
      currency_token: currency.id,
      vintage_item: vintageId,
      extra_data: {
        ...query,
        language: locale
      }
    }

    const payload = {
      amount: finalPrice.total_amount,
      currency_token: currency.id,
      url_ok: `${frontURL}/compensation/?${searchParams}`,
      url_ko: `${frontURL}/compensation/?error=error`,
      initials,
      offset,
      ticket_data: personalInfo,
      source: 'whitelabel',
    }
    localStorage.setItem('ticket_data', JSON.stringify({ ...personalInfo, last_name: personalInfo.last_name || '⠀' }))
    localStorage.setItem('compensation_offset', JSON.stringify(offset))
    const page_name = WHITELABEL_PAGES[route]
    const trackPayload: TrackPayload = {
      url: page_name, event: 'ACTION_CLICK',
      eventValue: 'BUY_NOW', integration: initials,
    }
    trackEvent(trackPayload)
    gtm_view({ ...trackPayload, page_name, url: location.href })

    const data = await createCreditCardOrder(payload)
    setForm(data)
    setTimeout(() => formRef.current?.submit(), 50)

  }
  const isDisabled = !terms || basic_fields(personalInfo.customer_type, t).filter(f => f.name)
    .map(f => f.name).some(k => !personalInfo[k]) || personalInfo.vat_number &&
    fiscal_fields(personalInfo.customer_type, t).map(f => f.name).some(k => !personalInfo[k]) || step > 3 || step < 3
    || selectedProject.kgco2_minimum_purchase > amount * 1000
  return (
    <div>
      <form ref={formRef} action={process.env.NEXT_PUBLIC_REDSYS_PAID_URL} method="POST" style={{ display: 'none' }}      >
        <input value={form?.Ds_SignatureVersion} type="hidden" name="Ds_SignatureVersion" />
        <input value={form?.Ds_MerchantParameters} type="hidden" name="Ds_MerchantParameters" />
        <input value={form?.Ds_Signature} type="hidden" name="Ds_Signature" />
      </form>
      <div className="redsys-button-container">
        <BaseButton label={t('general.' + (step > 2 ? 'OffsetNow' : 'Continue'))} onClick={handleClick} disabled={isDisabled} />
      </div>
    </div>
  )
}

export default RedsysForm
