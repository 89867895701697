
import React from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { getFinalPrice, selectAmount, selectCurrencies, selectCurrency, selectSelectedProject, selectWhitelabel, setSelectedProject } from 'src/features/compensation/compensationSlice'
import { Project } from 'src/models/project'
import { ProjectCard } from '@climatetrade/components'
import styles from './ProjectCardContainer.module.scss'
import { useTranslation } from 'next-i18next'
import { getInitials } from 'src/app/helpers'
import { useRouter } from 'next/router'

const ProjectCardContainer: React.FC<{ project: Project, setVisible: Function }> = ({ project, setVisible }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { query } = useRouter()
  const selectedProject = useAppSelector(selectSelectedProject)
  const amount = useAppSelector(selectAmount)
  const currencies = useAppSelector(selectCurrencies)
  const currency = useAppSelector(selectCurrency)
  const { secondary_color } = useAppSelector(selectWhitelabel)
  const handleSelectProject = () => {
    dispatch(setSelectedProject(project))
    const initials = getInitials(query, location.host)
    dispatch(getFinalPrice(initials, +amount))
  }
  const isSelected = selectedProject?.id === project.id

  return (
    <div className={styles['project-card-container']} onClick={handleSelectProject}
      style={{
        border: isSelected && '5px solid ' + (secondary_color || 'unset'),
        margin:isSelected && '0.84rem'
      }}>
      {selectedProject?.id === project.id && <div className="selected"
        style={{ backgroundColor: secondary_color ? secondary_color : '#1c64f2' }}
      >{t('general.Selected')}</div>}
      <div className="more-info" onClick={() => setVisible(true)}>{t('general.MoreInfo')}</div>
      <ProjectCard useFinalPrice={true} project={project} currencyList={currencies} userCurrencyCode={currency.code} />
    </div >
  )
}
export default ProjectCardContainer
