import { useTranslation } from 'next-i18next'
import React from 'react'
import { Project } from 'src/models/project'

const ProjectInfo: React.FC<{ project: Project }> = ({ project }) => {
  const { t } = useTranslation()
  const fields = ['country.name', 'billing_company_name', 'billing_address', 'type', 'tags']
  return (
    <>
      {fields.filter(f => project[f]?.length > 0).map(field => (
        <div className="project-info-field" key={project.id + '-' + field}>
          <div className="field-name">{t('projectFields.' + field)}</div>
          <div className="field-value">{project[field]}</div>
        </div>
      ))}
      {project.url && <div className="project-info-field" >
        <div className="field-name">{t('projectFields.url')}</div>
        <a target="_blank" rel="noreferrer noopener" href={project.url} className="field-value">{project.url}</a>
      </div >}
      <div className="project-info-field" >
        <div className="field-name">{t('projectFields.bc_address')}</div>
        <a target="_blank" rel="noreferrer noopener" className="field-value"
          href={`${process.env.NEXT_PUBLIC_ALGORAND_EXPLORER}/address/${project.bc_address}`} >
          {project.bc_address}
        </a>
      </div>
    </>
  )
}

export default ProjectInfo
