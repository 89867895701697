import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React from 'react'
import CreditCardIframe from 'src/components/CreditCardIframe/CreditCardIframe'
import styles from './PaymentContainer.module.scss'
const PaymentContainer = () => {
  const { t } = useTranslation()
  return (
    <div className={styles['paymentContainer']} id="paymentContainer">
      <div className="payment-title">
        <Image width={20} height={20} src="/images/icons/radio.svg" alt="radio-icon" />
        {t('payment.CreditDebitCard')}
      </div>
      <CreditCardIframe />
    </div >
  )
}

export default PaymentContainer
