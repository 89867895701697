import { Select, SelectProps } from 'antd'
import React from 'react'

const { Option } = Select

interface CountrySelectProps extends SelectProps {
  countries?: {
    id: number
    name: string
  }[]
}
const CountrySelect: React.FC<CountrySelectProps> = ({ countries, ...props }) => {

  return (
    <Select {...props}>
      {countries.map(c => <Option label={c.name} value={c.id} key={c.id + '-' + c.name}>{c.name}</Option>)
      }
    </Select >
  )
}
export default CountrySelect
