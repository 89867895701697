import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React from 'react'
import { getFormattedDate } from 'src/app/helpers'
import { Project } from 'src/models/project'

const ProjectCertification: React.FC<{ project: Project }> = ({ project }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const fields = ['validator', 'status', 'first_verifier', 'credit_start', 'credit_end', 'standard']
  return (
    <>
      {fields.filter(f => project[f]?.length > 0).map(field => (
        <div className="project-info-field" key={project.id + '-' + field}>
          <div className="field-name">{t('projectFields.' + field)}</div>
          <div className="field-value">{field.startsWith('credit_') ? getFormattedDate(new Date(project[field]), locale) : project[field]}</div>
        </div>
      ))}
      {(project.validation_report || project.pdd) && <div className="project-info-field" >
        <div className="field-name">{t('projectFields.ValidationDocumentation')}</div>
        <div className="field-value">
          {project.validation_report && <a target="_blank" rel="noreferrer noopener" href={project.validation_report} >
            Report.pdf
          </a>}<br />
          {project.pdd && <a target="_blank" rel="noreferrer noopener" href={project.pdd} >
            PDD.pdf
          </a>}
        </div>
      </div>}
    </>
  )
}

export default ProjectCertification
