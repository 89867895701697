import type { NextPage } from 'next'
import Head from 'next/head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { i18n, useTranslation } from 'next-i18next'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { getCountries, getCurrencies, getProjects, selectStep, selectWhitelabel, setAmount, setStep } from 'src/features/compensation/compensationSlice'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import AmountView from 'src/containers/AmountView/AmountView'
import MainStepper from 'src/containers/MainStepper/MainStepper'
import CartResume from 'src/components/CartResume/CartResume'
import FAQ from 'src/containers/FAQ/FAQ'
import { postVisitor, trackEvent } from 'src/features/visitors/actions'
import { STEPS, WHITELABEL_PAGES } from 'src/features/visitors/constants'
import { gtm_view } from 'src/app/helpers'
import { TrackPayload } from 'src/features/visitors/models'
import EmbeddedAmount from 'src/containers/Embedded/EmbeddedAmount'
import ChooseProject from 'src/containers/ChooseProject/ChooseProject'
import PersonalInfo from 'src/containers/PersonalInfo/PersonalInfo'
import PaymentContainer from 'src/containers/PaymentContainer/PaymentContainer'
import Spinner from 'src/components/Spinner/Spinner'
interface IndexPageProps {
  initials: string
  isCloudWatch: boolean
}
const IndexPage: NextPage<IndexPageProps> = ({ initials, isCloudWatch }) => {

  const { t } = useTranslation()
  const router = useRouter()
  const step = useAppSelector(selectStep)
  const { title, embedded, currency_token } = useAppSelector(selectWhitelabel)
  const dispatch = useAppDispatch()
  const getInitials = useCallback(() => router.query.initials as string ||
    initials === 'calculators' && 'events' || initials || 'localhost', [router.query.initials])

  useEffect(() => {
    dispatch(getCurrencies())
    dispatch(getCountries())
  }, [dispatch])
  const sendTrackEvent =(event:string, eventValue?:string) => {
    const integration = getInitials()
    const page_name = WHITELABEL_PAGES[router.route]
    const payload: TrackPayload = {
      url: page_name, event, eventValue: eventValue || page_name, integration
    }
    trackEvent(payload)
    gtm_view({ ...payload, page_name, url: location.href, integration  })
  }
  useEffect(() => {
    if (!isCloudWatch && currency_token) {
      sendTrackEvent( 'STEP_VIEW',STEPS[step])
    }
  }, [step])

  useEffect(() => {
    if (!isCloudWatch) {
      const initials = getInitials()
      const initialValue = ['tones', 'tonnes', 'amount'].map(k => router.query[k]).find(v => v) as string
      if (initialValue) {
        dispatch(setAmount(initialValue))
        if (router.query.skiptonnes === 'true') { dispatch(setStep(2)) }
      }
      dispatch(getProjects(initials, initialValue || '1', router.locale))
      .then(() => {sendTrackEvent('PAGE_VIEW')})
    }
  }, [router.query, dispatch, initials, router.locale, getInitials, router.route])
  useEffect(() => {
    if (!isCloudWatch) {
      postVisitor()
    }
  }, [])
  const renderHead = () => <Head>
  <title>{title || t('head.Title', { initials })}</title>
</Head>
  if(!currency_token) {
    return (<div>
      {renderHead()}
      <Spinner/>
      </div>
      )
    }
  const EmbeddedComponents = [EmbeddedAmount, EmbeddedAmount, ChooseProject, PersonalInfo, PaymentContainer]
  const CurrentEmbeddedComponent = EmbeddedComponents[step] || EmbeddedAmount
  return (
    <div>
      {renderHead()}
      {embedded ?
        <div className="main-container" style={{alignItems: 'flex-start'}}>
          <CurrentEmbeddedComponent />
          <CartResume />
        </div>
        : <>
          <AmountView showAmountInput={step === 0} />
          <div className="main-container">
            {step === 0 ? <FAQ /> :
              <>
                <MainStepper />
                <CartResume />
              </>
            }
          </div>
        </>
      }
    </div >
  )
}
export async function getServerSideProps({ locale, req }) {
  const initials = req.headers.host.split('.').reverse()[2] || 'localhost'
  if (process.env.NODE_ENV === 'development') {
    await i18n?.reloadResources()
  }
  const isCloudWatch = req.rawHeaders.join('').includes('CloudWatch')
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      initials,
      isCloudWatch
    },
  }
}

export default IndexPage
