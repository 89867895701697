import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import styles from './ChooseProject.module.scss'
import { useAppDispatch, useAppSelector } from 'src/app/hooks'
import { selectAmount, selectProjects, selectSelectedProject, selectStep, setStep } from 'src/features/compensation/compensationSlice'
import ProjectCardContainer from 'src/components/ProjectCard/ProjectCardContainer'
import BaseButton from 'src/components/BaseButton/BaseButton'
import ProjectModal from 'src/components/ProjectModal/ProjectModal'
import { Alert } from 'antd'
const ChooseProject: React.FC = () => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)
  const step = useAppSelector(selectStep)
  const projects = useAppSelector(selectProjects)
  const amount = +useAppSelector(selectAmount)
  const selectedProject = useAppSelector(selectSelectedProject)
  const dispatch = useAppDispatch()
  const handleNextStep = () => dispatch(setStep(step + 1))
  const min = selectedProject?.kgco2_minimum_purchase / 1000
  return (
    <div className={styles.ChooseProject}>
      <div className="projects">
        {projects?.map(p => <ProjectCardContainer project={p} key={p.id} setVisible={setVisible} />)}
      </div>
      {min > amount && <Alert message={t('general.MinimumPurchaseWarning', { min })} type="warning" showIcon />}
      <br />

      <BaseButton type="button" label={t('general.Continue')}
        disabled={min > amount} onClick={handleNextStep} />
      <ProjectModal visible={visible} setVisible={setVisible} />
    </div>
  )
}
export default ChooseProject
